import "../src/styles/preflight.css";
import "../src/tailwind.css";
import "../src/styles/system.css";
import "../src/styles/table.css";
import "../src/styles/np.css";
import "../src/styles/nav.css";
import "../src/styles/montserrat.css";
import "../src/globals.ts";
import * as React from "react";
import App from "next/app";
import Head from "next/head";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { useRouter } from "next/router";
import Script from "next/script";
import { make as TranslationProvider } from "common/src/bindings/Translation.bs";
import { make as AppShell } from "../src/app-core/AppShell.bs";
import { make as AppLayout } from "../src/app-core/AppLayout.bs";
import { init as initTranslations } from "common/src/bindings/Translation2.ts";
import { REVIQ_THRESHOLD } from "common/src/Config.ts";

export const cache = createCache({ key: "css", prepend: true });

export default function MyApp(props) {
  if (typeof window !== "undefined") {
    localStorage.setItem("ab2", props.ab);
  }

  const { Component, pageProps, err, router } = props;
  const locale = router.locale;
  const [_translations, _setTranslations] = React.useState(() => {
    if (typeof window === "undefined") {
      const common = require(`common/src/static/common_${locale}.json`);
      const units = require(`common/src/static/units_${locale}.json`);
      const traits = require(`common/src/static/traits_${locale}.json`);
      const items = require(`common/src/static/items_${locale}.json`);
      const augments = require(`common/src/static/augments_${locale}.json`);
      initTranslations(common, units, traits, items, augments);

      const unitss9 = require(`common/src/static-s9/units_${locale}.json`);
      const traitss9 = require(`common/src/static-s9/traits_${locale}.json`);
      const itemss9 = require(`common/src/static-s9/items_${locale}.json`);
      const augmentss9 = require(`common/src/static-s9/augments_${locale}.json`);
      const setInfo9 = require(`common/src/static-s9/set_info_${locale}.json`);
      initTranslations(setInfo9, unitss9, traitss9, itemss9, augmentss9);

      const unitss95 = require(`common/src/static-s95/units_${locale}.json`);
      const traitss95 = require(`common/src/static-s95/traits_${locale}.json`);
      const itemss95 = require(`common/src/static-s95/items_${locale}.json`);
      const augmentss95 = require(`common/src/static-s95/augments_${locale}.json`);
      const setInfo95 = require(`common/src/static-s95/set_info_${locale}.json`);
      initTranslations(setInfo95, unitss95, traitss95, itemss95, augmentss95);

      const unitss36 = require(`common/src/static-s36/units_${locale}.json`);
      const traitss36 = require(`common/src/static-s36/traits_${locale}.json`);
      const itemss36 = require(`common/src/static-s36/items_${locale}.json`);
      const augmentss36 = require(`common/src/static-s36/augments_${locale}.json`);
      const setInfo36 = require(`common/src/static-s36/set_info_${locale}.json`);
      initTranslations(setInfo36, unitss36, traitss36, itemss36, augmentss36);

      const unitss10 = require(`common/src/static-s10/units_${locale}.json`);
      const traitss10 = require(`common/src/static-s10/traits_${locale}.json`);
      const itemss10 = require(`common/src/static-s10/items_${locale}.json`);
      const augmentss10 = require(`common/src/static-s10/augments_${locale}.json`);
      const setInfo10 = require(`common/src/static-s10/set_info_${locale}.json`);
      initTranslations(setInfo10, unitss10, traitss10, itemss10, augmentss10);

      const unitss11 = require(`common/src/static-s11/units_${locale}.json`);
      const traitss11 = require(`common/src/static-s11/traits_${locale}.json`);
      const itemss11 = require(`common/src/static-s11/items_${locale}.json`);
      const augmentss11 = require(`common/src/static-s11/augments_${locale}.json`);
      const setInfo11 = require(`common/src/static-s11/set_info_${locale}.json`);
      initTranslations(setInfo11, unitss11, traitss11, itemss11, augmentss11);

      const unitss56 = require(`common/src/static-s56/units_${locale}.json`);
      const traitss56 = require(`common/src/static-s56/traits_${locale}.json`);
      const itemss56 = require(`common/src/static-s56/items_${locale}.json`);
      const augmentss56 = require(`common/src/static-s56/augments_${locale}.json`);
      const setInfo56 = require(`common/src/static-s56/set_info_${locale}.json`);
      initTranslations(setInfo56, unitss56, traitss56, itemss56, augmentss56);

      const unitss12 = require(`common/src/static-s12/units_${locale}.json`);
      const traitss12 = require(`common/src/static-s12/traits_${locale}.json`);
      const itemss12 = require(`common/src/static-s12/items_${locale}.json`);
      const augmentss12 = require(`common/src/static-s12/augments_${locale}.json`);
      const setInfo12 = require(`common/src/static-s12/set_info_${locale}.json`);
      initTranslations(setInfo12, unitss12, traitss12, itemss12, augmentss12);

      const unitss13 = require(`common/src/static-s13/units_${locale}.json`);
      const traitss13 = require(`common/src/static-s13/traits_${locale}.json`);
      const itemss13 = require(`common/src/static-s13/items_${locale}.json`);
      const augmentss13 = require(`common/src/static-s13/augments_${locale}.json`);
      const setInfo13 = require(`common/src/static-s13/set_info_${locale}.json`);
      initTranslations(setInfo13, unitss13, traitss13, itemss13, augmentss13);

      return [
        [`${locale}_common`, common],
        [`${locale}_units`, units],
        [`${locale}_traits`, traits],
        [`${locale}_items`, items],
        [`${locale}_augments`, augments],
      ];
    } else if (window.initLocalei18n && window.initLocalei18n === locale) {
      // const unitss11 = require(`common/src/static-s11/units_${locale}.json`);
      // const traitss11 = require(`common/src/static-s11/traits_${locale}.json`);
      // const itemss11 = require(`common/src/static-s11/items_${locale}.json`);
      // const augmentss11 = require(`common/src/static-s11/augments_${locale}.json`);
      // const setInfo11 = require(`common/src/static-s11/set_info_${locale}.json`);
      // initTranslations(setInfo11, unitss11, traitss11, itemss11, augmentss11);
      // return [
      //   [`${locale}_common`, window.initCommoni18n],
      //   [`${locale}_units`, window.initUnitsi18n],
      //   [`${locale}_traits`, window.initTraitsi18n],
      //   [`${locale}_items`, window.initItemsi18n],
      //   [`${locale}_augments`, window.initAugmentsi18n],
      // ];
    } else {
      return [];
    }
  });

  React.useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      if (typeof window !== "undefined") {
        console.log = () => {};
      }
    } else {
      // console.nativeLog = console.error;
      // console.error = function (a) {
      //   if (
      //     a &&
      //     a.indexOf("Warning: findDOMNode is deprecated in StrictMode") > -1
      //   ) {
      //     return;
      //   }
      //   console.nativeLog(a);
      // };
    }

    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  const routerHook = useRouter();
  React.useEffect(() => {
    const handleRouteChange = (url) => {
      window.gtag("config", process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS, {
        page_path: url,
      });
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    routerHook.events.on("routeChangeComplete", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      routerHook.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [routerHook.events]);

  return (
    <>
      <Script src={`/init.js`} strategy="beforeInteractive" />
      <Script
        src={`https://ap.tft.tools/static/s9/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s9/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s95/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s95/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s36/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s36/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s56/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s56/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s10/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s10/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s11/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s11/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s12/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s12/data.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s13/${locale}.js`}
        strategy="beforeInteractive"
      />
      <Script
        src={`https://ap.tft.tools/static/s13/data.js`}
        strategy="beforeInteractive"
      />
      {props.ab < REVIQ_THRESHOLD ? (
        <Script type="module" src="//js.rev.iq" data-domain="tactics.tools" />
      ) : (
        <Script
          src="https://s.nitropay.com/ads-859.js"
          strategy="beforeInteractive"
        />
      )}
      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-TW5TF9Q0Z8"
      />
      <CacheProvider value={cache}>
        <Head>
          <title>
            TFT Meta, Stats, Comps, Match History and more Teamfight Tactics
            tools
          </title>
          <link rel="apple-touch-icon" href="/android-chrome-192x192.png" />
          <meta property="og:site_name" content="tactics.tools" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta
            key="description"
            name="description"
            content="In depth stats, analytics, match history, team builder, and various other tools you need to master Teamfight Tactics Set 12."
          />
          {props.ab < REVIQ_THRESHOLD ? null : (
            <script
              type="text/javascript"
              dangerouslySetInnerHTML={{
                __html: `window["nitroAds"] = window["nitroAds"] || {
                createAd: function() {
                  window.nitroAds.queue.push(["createAd", arguments]);
                },
                queue: []
              };`,
              }}
            />
          )}
          <script
            dangerouslySetInnerHTML={{
              __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-TW5TF9Q0Z8');
          `,
            }}
          ></script>
          <script
            dangerouslySetInnerHTML={{
              __html: `
            (function(e,t){var n=e.amplitude||{_q:[],_iq:{}};var r=t.createElement("script")
            ;r.type="text/javascript"
            ;r.integrity="sha384-MBHPie4YFudCVszzJY9HtVPk9Gw6aDksZxfvfxib8foDhGnE9A0OriRHh3kbhG3q"
            ;r.crossOrigin="anonymous";r.async=true
            ;r.src="https://cdn.amplitude.com/libs/amplitude-8.16.1-min.gz.js"
            ;r.onload=function(){if(!e.amplitude.runQueuedFunctions){console.log(
            "[Amplitude] Error: could not load SDK")}};var s=t.getElementsByTagName("script"
            )[0];s.parentNode.insertBefore(r,s);function i(e,t){e.prototype[t]=function(){
            this._q.push([t].concat(Array.prototype.slice.call(arguments,0)));return this}}
            var o=function(){this._q=[];return this};var a=["add","append","clearAll",
            "prepend","set","setOnce","unset","preInsert","postInsert","remove"];for(
            var c=0;c<a.length;c++){i(o,a[c])}n.Identify=o;var l=function(){this._q=[]
            ;return this};var u=["setProductId","setQuantity","setPrice","setRevenueType",
            "setEventProperties"];for(var p=0;p<u.length;p++){i(l,u[p])}n.Revenue=l;var d=[
            "init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut",
            "setVersionName","setDomain","setDeviceId","enableTracking",
            "setGlobalUserProperties","identify","clearUserProperties","setGroup",
            "logRevenueV2","regenerateDeviceId","groupIdentify","onInit","onNewSessionStart"
            ,"logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId",
            "getDeviceId","getUserId","setMinTimeBetweenSessionsMillis",
            "setEventUploadThreshold","setUseDynamicConfig","setServerZone","setServerUrl",
            "sendEvents","setLibrary","setTransport"];function v(t){function e(e){
            t[e]=function(){t._q.push([e].concat(Array.prototype.slice.call(arguments,0)))}}
            for(var n=0;n<d.length;n++){e(d[n])}}v(n);n.getInstance=function(e){e=(
            !e||e.length===0?"$default_instance":e).toLowerCase();if(
            !Object.prototype.hasOwnProperty.call(n._iq,e)){n._iq[e]={_q:[]};v(n._iq[e])}
            return n._iq[e]};e.amplitude=n})(window,document);
            amplitude.getInstance().init("851d0d4df019cf005a45c1b9fcde14f0");
            window.ampLog = (e, d) => amplitude.getInstance().logEvent(e, d);window.ampSetUserId = (uid) => amplitude.setUserId(uid);`,
            }}
          />
          {/* <script
            src="https://hb.vntsm.com/v3/live/ad-manager.min.js"
            type="text/javascript"
            data-site-id="64cbc5b27e562712d6a33973"
            data-mode="scan"
            async
          ></script> */}
        </Head>
        <TranslationProvider initialLocale={locale}>
          <StyledEngineProvider injectFirst>
            <AppShell>
              <AppLayout>
                <Component {...pageProps} err={err} />
              </AppLayout>
            </AppShell>
          </StyledEngineProvider>
        </TranslationProvider>
      </CacheProvider>
    </>
  );
}

// export async function reportWebVitals(metric) {
//   const amplitudeMod = await import("amplitude-js");
//   const amplitude = amplitudeMod.default;
//   const { name, ...rest } = metric;
//   amplitude.logEvent(`perf-${metric.name}`, rest);
//
//   const { id, label, value } = rest;
//   window.gtag("event", name, {
//     event_category:
//       label === "web-vital" ? "Web Vitals" : "Next.js custom metric",
//     value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
//     event_label: id, // id unique to current page load
//     non_interaction: true, // avoids affecting bounce rate.
//   });
// }

MyApp.getInitialProps = async (context) => {
  const req = context.ctx.req;
  const pageProps = await App.getInitialProps(context); // Retrieves page's `getInitialProps`

  let ab = undefined;
  if (req?.headers?.cookie) {
    ab = req.headers.cookie
      .split("; ")
      .find((cv) => cv.startsWith("tt-key="))
      ?.slice(7);
  }

  return {
    ...pageProps,
    ab: (ab && Number(ab)) ?? Math.floor(Math.random() * 1000),
  };
};
